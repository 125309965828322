import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import NextNProgress from "nextjs-progressbar";
import '../styles/globals.scss';
import '../styles/mobile.scss';
import { parseCookies } from 'nookies';
import Cookies from 'js-cookie';
import Header from '../components/header';
import Head from 'next/head'
import Footer from '../components/footer';
import "../styles/globals.css";
import IdleTimerContainer from "../components/IdleTimerContainer";
import { useRouter } from 'next/router';
//import { Footer } from 'rsuite';



function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
    import("bootstrap/js/src/collapse.js");
    const token = Cookies.get('token')
    // if (!token && router.asPath!=='/') {router.push('/')}
  }, []);

  const scr = () => {
    try {
      window.Userback = window.Userback || {};
      window.Userback.access_token = '6847|66221|u9LOO5f6cCU1TtBIx9vSqpjgxRtm8Up05yCqcfInZqGZFnUQwO';

      const f = function (d) {
        let s = d.createElement('script');
        s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
      }
      f(document)
    }
    catch (ex) {
    }
  }

  return (
    <>
      <Head>
        <title>Portail - Alliance Crédit</title>
      </Head>
      <Header />
      <NextNProgress options={{ showSpinner: false }} />
      <IdleTimerContainer />
      <Component {...pageProps} />
      <Footer/>
      {scr()}
    </>
  )
}

export default MyApp
