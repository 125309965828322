import Link from 'next/link'
import Router, { NextResponse, NextRequest, useRouter } from "next/router";
import Cookies from 'js-cookie';
import Logo from './logo';
import { Nav, Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import langTrans from './i18n';
import { ImMenu } from "react-icons/im";
import { IoClose } from "react-icons/io5";
const Footer = (props) => {

    const router = useRouter();
    const locale = Cookies.get('NEXT_LOCALE') ? Cookies.get('NEXT_LOCALE') : router.locale
    const lang = langTrans[locale].footer;
    

    const token = Cookies.get('token');
    const role = Cookies.get('role');
    const name = Cookies.get('name');

    let user = token ? true : false;

    const currentYear = new Date().getFullYear();
    

  

    // useEffect(() => { //componentDidMount
    //     const l = Cookies.get('NEXT_LOCALE')
    //     setCurrentLanguage(l)
    //     if (l !== router.locale && !user) router.push(router.asPath, router.asPath, { locale: l })


    //     router.events.on('routeChangeStart', () => {
    //         setMyToggle(false)
    //     });
    //     router.events.on('routeChangeComplete', (url) => {
    //         setMyToggle(false)
    //     });

    // }, [Cookies.get('NEXT_LOCALE')])

    // const setLanguage = () => {
    //     if (language == 'en') {
    //         Cookies.set('NEXT_LOCALE', 'fr')
    //         setTimeout(() => {
    //             router.reload();
    //         }, 1000);
    //     }
    //     else {
    //         Cookies.set('NEXT_LOCALE', 'en')
    //         setTimeout(() => {
    //             router.reload();
    //         }, 1000);
    //     }
    // }

    

    if(router.pathname.includes('/files') || router.pathname.includes('/external/flinks')){
        return <></>
    }

    if (router.asPath == "/"
        || router.asPath == '/forgot-password'
        || router.pathname == '/reset-password'
        || router.asPath == '/change-password'
        || router.pathname == '/external/external-bank'
        || router.pathname == '/external/plaid'
        || router.pathname == '/external/external-supplier'
        || router.pathname == '/external/completed'
        || router.pathname == '/external/thankyou'
        || router.asPath == '/reset-password-successful'
        || router.pathname == '/external/user-onboarding'
        || router.pathname == '/external/confirmation'
        || router.pathname == '/external/user-edit'
        || router.pathname == '/denunciation'
        || router.pathname == '/credit-reports/report'
        || router.pathname == '/credit-reports/display-esc'
    ) {
        user = true;
        return <></>;
    }

    if (router.pathname == "/404" || router.pathname == "_error"  || router.pathname == '/reset-password-successful' ) {

        user = false;
        return <></>
    }

    if (user && token && name) {
        return (
            <>
                <div >
                    <div className="footer_wrap" style={{display:"flex",
                     flexDirection:"column", alignItems:"center", justifyContent:"center", padding:"20px"}}>
                        <div className="container" style={{display : "flex", justifyContent:"space-between"}}>
                        <div>
                                {lang.copyright} {currentYear} {lang.copyright_end}
                                </div>
                                <div>
                                {lang.contact} 
                                 <Link href="mailto:support@alliancecredit.ca">
                                <a>{lang.support}</a>
                                </Link>
                                </div>
                      
</div>
                    </div>
                </div>
            </>
        )
    }
    else return (
        <div className="footer">
            <div className="footer_wrap"></div>
        </div>
    )
}



export default Footer;
