import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

function SessionTimeOut() {
  let userAuth = Cookies.get("token");
  let router = useRouter();

  const idleTime = useRef(null);

  const onIdle = () => {
    if (userAuth) {
      Cookies.remove("token");
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("userid");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("viewed_company_id");
      Cookies.remove("userData");
      Cookies.remove("NEXT_LOCALE");
      router.push("/");
    }
  };

  return <IdleTimer ref={idleTime} timeout={21600000} onIdle={onIdle} />;
}

export default SessionTimeOut;
